/**
 * @author Mayura Pradeep Patil
 * @email mayura.pradeepa.patil@rockwellcollins.com
 * @create date 2022-02-14 12:44:03
 * @modify date 2022-02-14 12:44:03
 * @desc Tap Test dev environment
 */

 export const environment = {
	production: true,
	baseUrl: 'https://apidev-taptest.azurewebsites.net/',
};

