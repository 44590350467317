/**
 * © Rolling Array https://rollingarray.co.in/
 *
 *
 * @summary Array key constant
 * @author code@rollingarray.co.in
 *
 * Created at     : 2021-12-08 12:21:47 
 * Last modified  : 2021-12-08 12:30:37
 */

 
 
 export class ArrayKeyConstant
 {


 
     public static readonly FFT_RANGE: number[] = [512, 1024, 2048, 4096];
 }
 