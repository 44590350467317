/**
 * @author Ranjoy Sen
 * @email ranjoy.sen@rockwellcollins.com
 * @create date 2021-11-24 21:44:07
 * @modify date 2021-11-24 21:44:07
 * @desc App component
 */

import { Component } from '@angular/core';
@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent
{
	/**
	 * App pages of app component
	 */
	public appPages = [
		{ title: 'Home', url: '/home', icon: 'home' },
		// { title: 'Pulse-Detection', url: '/pulse-detection', icon: 'pulse' }
	];

	/**
	 * Labels  of app component
	 */
	public labels = [];

	private version = 1.1;

	/**
	 * Creates an instance of app component.
	 */
	constructor()
	{
		for (let [key, value] of Object.entries(localStorage))
		{
			this.labels.push(`${key}`);
		}
	}
}
