/**
 * @author Mayura Pradeep Patil
 * @email mayura.pradeepa.patil@rockwellcollins.com
 * @create date 2022-02-14 12:44:03
 * @modify date 2022-02-14 12:44:03
 * @desc Tap Test main
 */


import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
