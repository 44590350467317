import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ArrayKeyConstant } from 'src/app/shared/constant/array.constant';

@Component({
	selector: 'app-settings-popover',
	templateUrl: './settings-popover.component.html',
	styleUrls: ['./settings-popover.component.scss'],
})
export class SettingsPopoverComponent implements OnInit {
	/**
	 * Custom steps of audio player component
	 */
	private _range: any;

	/**
	 * Gets custom steps
	 */
	get range() {
		return this._range;
	}

	/**
	 * Fft range index of audio player component
	 */
	fftRangeIndex: number = 1;

	/**
	 * Custom fftrange of audio player component
	 */
	readonly customFFTRange = ArrayKeyConstant.FFT_RANGE;

	constructor(private popover: PopoverController) {}

	/**
	 * Om init
	 */
	ngOnInit() {
		let fftValue = parseInt(localStorage.getItem('fft'));
		for(let i=0; i<this.customFFTRange.length-1; i++) {
			if(this.customFFTRange[i]==fftValue) {
				this.fftRangeIndex=i;
			}
		}	
		this.changeInFFT();	
	}

	/**
	 * Popover close
	 */
	private async close() {
		this.popover.dismiss(this._range);
	}

	/**
	 * Change in FFT
	 */
	private async changeInFFT() {
		this._range = this.customFFTRange[this.fftRangeIndex];
	}
}
